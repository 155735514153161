<template>
  <div>
    <CCard class="p-0 m-0 form-card" >
      <CCardHeader>
        <label class="header-title">ID発行管理</label>
      </CCardHeader>
    </CCard>
    <CCard class="p-0 m-0 grid-card">
      <CCardBody class="p-0">
        <MyBeeSearchBar @goodPaging="goodPaging" @changePage="changePage" @devSearch="devSearch"
                        :curr-page.sync="pagingParams.currPage"
                        :per-page.sync="pagingParams.perPage"
                        :per-page-list="perPageList"
                        :pages="pages"
                        :search-bar-place-holder="searchBarPlaceHolder"
                        :keyword.sync="pagingParams.keyword"></MyBeeSearchBar>
        <BeeGridTable
            ref="bee"
            border
            stripe
            :showFilter="false"
            :columns="columns"
            :loading="isLoading"
            :data="rows"
            :showPager="false"
            :maxHeight="gridCardHeight"
            highlightRow
            no-data-text="データがありません。"
            no-filtered-data-text=""
            :context="context"
            :span-method="customSpan"
            :showVerticalScrollBar="false"
            :showHorizontalScrollBar="false"
            >
          <!-- HEADER ソート可能にするために 必要-->
          <template slot-scope="{ column,index }" slot="userIdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>
          <template slot-scope="{ column,index }" slot="roleHeader"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>
          <template slot-scope="{ column,index }" slot="accountStatusLabelHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>

          <template slot-scope="{ column,index }" slot="lockedTimeHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>
          <template slot-scope="{ column,index }" slot="createdPassedDateHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>


          <!-- TDの中身 -->
          <template slot-scope="{ row,index }" slot="userId"><div class="text-left"><span>{{row.userId}}</span></div></template>
          <template slot-scope="{ row,index }" slot="email"><div class="text-left"><span>{{row.email}}</span></div></template>
          <template slot-scope="{ row,index }" slot="loginId"><div class="text-left"><span>{{row.loginId}}</span></div></template>
          <template slot-scope="{ row,index }" slot="accountCreated"><div class="text-left"><span>{{row.accountCreated}}</span></div></template>
          <template slot-scope="{ row,index }" slot="createdPassedDate"><div class="text-left"><span>{{row.createdPassedDate}}</span></div></template>
          <template slot-scope="{ row,index }" slot="hospitalName"><div class="text-left"><span>{{row.hospitalName}}</span></div></template>
          <template slot-scope="{ row,index }" slot="agencyName"><div class="text-left"><span>{{row.agencyName}}</span></div></template>
          <template slot-scope="{ row,index }" slot="agencyBranchName"><div class="text-left"><span>{{row.agencyBranchName}}</span></div></template>


          <template slot-scope="{ row,index }" slot="userName"><div class="text-left"><span>{{row.userName}}</span></div></template>
          <template slot-scope="{ row,index }" slot="role">
            <span >{{getRoleName(row.role)}}</span>
          </template>
          <template slot-scope="{ row,index }" slot="lockedTime">
            <div>
              <div>{{ row.lockedTime }}</div>
              <!-- <CButton v-show="row.lockedTime" color="warning" size="sm" @click="lockRelease(row,index)">解除</CButton>
              <CButton v-show="row.notLoginAbleFlg" color="warning ml-1" size="sm" @click="loginTimeExtension(row)">延長</CButton> -->
            </div>
          </template>
          
          
          <template slot-scope="{ row,index }" slot="after">
              <div class="float-center">
                <CButton v-show="row.accountStatus == 'BEFORE_LOGIN'" color="warning" size="sm" @click="showMailTemplate(row,index,'resend')">メール再送</CButton>
                <CButton v-show="row.accountStatus == 'BEFORE_PASSWORD_CHANGE'" color="warning" size="sm" @click="showMailTemplate(row,index,'resend')">メール再送</CButton>
                <CButton v-show="row.accountStatus == 'LOGIN_FAILED'" color="info" size="sm" @click="showMailTemplate(row,index,'extendPeriod')" >期間延長</CButton>
                <CButton v-show="row.accountStatus == 'LOCKED'" color="danger" size="sm" @click="showMailTemplate(row,index,'releaseLock')">ロック解除</CButton>
                <!-- <CButton v-show="row.accountStatus == 'ARCHIVE'" color="warning" size="sm" @click="lockRelease(row,index)">アーカイブ解除</CButton> -->
            </div>
          </template>
<!--          <template slot-scope="{ row,index }" slot="after">-->
<!--            <div>-->
<!--              <div>{{ row.lockedTime }}</div>-->
<!--              <CButton color="warning" size="sm" @click="lockRelease(row,index)">解除</CButton>-->
<!--              <CButton color="warning ml-1" size="sm" @click="loginTimeExtension(row)">延長</CButton>-->
<!--            </div>-->
<!--          </template>-->
        </BeeGridTable>
      </CCardBody>
    </CCard>

    <CModal :show.sync="showMailTemplateFlg"
            size="xl"
            :centered="true"
            :title="mailTemplateTitle +' (' +sendMailRowData.userName+')'">
      <div>
        <label class="header-title">メール本文</label>
      </div>
      <div>
        <div v-if="sendMailType == 'resend'">
          <div class="container">
            <p>{{ sendMailRowData.userName }}様</p>
            <br>
            <br>
            <div>この度は「オキシコネクト」にご登録いただき、誠にありがとうございます。</div>
            <br>
            <div>お客様のご依頼によりユーザー仮登録の通知を再送いたします。</div>
            <br>
            <div>このメールを受け取った時点では、まだユーザー本登録は完了しておりません。</div>
            <div>引き続き次の手順で本登録を行い、ログインが出来ることを確認してください。</div>
            <div>詳しくは、操作説明書をご覧ください。</div>
            <br>
            <div>１）ログインキーファイルの取得</div>
            <div class="lpad">下記URLにアクセスし、以下のログインIDと仮パスワードを入力してください。</div>
            <div class="lpad">その後、ログインキーファイルを取得してください。</div>
            <br>
            <div class="lpad">ログインID： xxx</div>
            <div class="lpad">仮パスワード： xxx</div>
            <div class="lpad">URL：xxx</div>
            <br>
            <div>※最初にお送りしたメールから仮パスワードを変更しています。こちらのパスワードでログインをお願いします。</div>
            <br>
            <div>２）パスワードの設定</div>
            <div class="lpad"> ログインキーファイルを選択し、ログインの後、画面の指示に従い新パスワードの設定をしてください。</div>
            <br>
            <div>なお、上記URLの有効期限は本日より7日間です。</div>
            <div>7日を過ぎますと、再度ユーザー仮登録のお手続きが必要となりますのでご注意ください。</div>
            <br>
            <div>※本メールにお心当たりがない場合は、お手数をお掛けして申し訳ございませんが破棄していただきますようお願いします。</div>
            <div>※本メールの送信アドレスは送信専用となっております。このメールへの返信によるご質問、お問い合わせは承りかねますのであらかじめご了承願います。</div>
            <br>
            <br>
            <div class="mailSignature">
              <div>＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝</div>
              <div>大陽日酸株式会社</div>
              <div>オキシコネクトサポート窓口</div>
              <br>
              <div>oxyconnect.info@tn-sanso.co.jp/ TEL 03-5788-8670</div>
              <div>営業時間：9：00～17：30（土日・祝祭日・年末年始休み）</div>
              <div>＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝</div>
            </div>
          </div>
        </div>
        <div v-if="sendMailType == 'extendPeriod'">
          <div class="container">
            <p>{{ sendMailRowData.userName }}様</p>
            <br>
            <br>
            <div>この度は「オキシコネクト」にご登録いただき、誠にありがとうございます。</div>
            <br>
            <div>お客様のご依頼によりユーザー仮登録の通知を再送いたします。</div>
            <br>
            <div>このメールを受け取った時点では、まだユーザー本登録は完了しておりません。</div>
            <div>引き続き次の手順で本登録を行い、ログインが出来ることを確認してください。</div>
            <div>詳しくは、操作説明書をご覧ください。</div>
            <br>
            <div>１）ログインキーファイルの取得</div>
            <div class="lpad">下記URLにアクセスし、以下のログインIDと仮パスワードを入力してください。</div>
            <div class="lpad">その後、ログインキーファイルを取得してください。</div>
            <br>
            <div class="lpad">ログインID： xxxx</div>
            <div class="lpad">仮パスワード： xxx</div>
            <div class="lpad">URL：{{baseUrl}}</div>
            <br>
            <div>※最初にお送りしたメールから仮パスワードを変更しています。こちらのパスワードでログインをお願いします。</div>
            <br>
            <div>２）パスワードの設定</div>
            <div class="lpad"> ログインキーファイルを選択し、ログインの後、画面の指示に従い新パスワードの設定をしてください。</div>
            <br>
            <div>なお、上記URLの有効期限は本日より7日間です。</div>
            <div>7日を過ぎますと、再度ユーザー仮登録のお手続きが必要となりますのでご注意ください。</div>
            <br>
            <div>※本メールにお心当たりがない場合は、お手数をお掛けして申し訳ございませんが破棄していただきますようお願いします。</div>
            <div>※本メールの送信アドレスは送信専用となっております。このメールへの返信によるご質問、お問い合わせは承りかねますのであらかじめご了承願います。</div>
            <br>
            <br>
            <div class="mailSignature">
              <div>＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝</div>
              <div>大陽日酸株式会社</div>
              <div>オキシコネクトサポート窓口</div>
              <br>
              <div>oxyconnect.info@tn-sanso.co.jp/ TEL 03-5788-8670</div>
              <div>営業時間：9：00～17：30（土日・祝祭日・年末年始休み）</div>
              <div>＝＝＝＝＝＝＝＝＝＝＝＝extendPeriod＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝</div>
            </div>
          </div>

        </div>
        <div v-if="sendMailType=='releaseLock'">
          <div class="container">
            <p>{{ sendMailRowData.userName }}様</p>
            <br>
            <br>
            <div>「オキシコネクト」をご利用いただきありがとうございます。</div>
            <br>
            <div>お客様のご依頼によりアカウントロックを解除いたしました。</div>
            <div>以下のURLより改めてログインいただきますようお願いします。</div>
            <br>
            <br>
            <div>URL：xxxx</div>
            <br>
            <div>※本メールにお心当たりがない場合は、お手数をお掛けして申し訳ございませんが破棄していただきますようお願いします。</div>
            <div>※本メールの送信アドレスは送信専用となっております。このメールへの返信によるご質問、お問い合わせは承りかねますのであらかじめご了承願います。</div>
            <br>
            <br>
            <div class="mailSignature">
              <div>＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝</div>
              <div>大陽日酸株式会社</div>
              <div>オキシコネクトサポート窓口</div>
              <br>
              <div>oxyconnect.info@tn-sanso.co.jp/ TEL 03-5788-8670</div>
              <div>営業時間：9：00～17：30（土日・祝祭日・年末年始休み）</div>
              <div>＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝</div>
            </div>
          </div>
        </div>
      </div>
      <div>
      </div>
      <div>
        <label class="header-title">メール送信先</label>
      </div>
      <div>
        本人：{{ sendMailRowData.email }}
      </div>
      <div v-if="sendMailRowData.loginStatusContacEmail">
        ID発行管理連絡先:{{ sendMailRowData.loginStatusContacEmail }}
      </div>

      <template #footer>
        <CButton @click="showMailTemplateFlg = false" color="danger">キャンセル</CButton>
        <CButton @click="callMailMethod()" color="success">OK</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import CommonMix from '@/views/aprod/js/mixin/CommonUtils'
import BeeMix from '@/views/aprod/js/mixin/beeUtils'
import MyBeeHeader from "@/component/parts/MyBeeHeader";
import MyBeeSearchBar from "@/component/parts/MyBeeSearchBar";
export default {
  components:{MyBeeHeader,MyBeeSearchBar},
  mixins:[CommonMix,BeeMix],
  name: 'IdManage',
  data: () => {
    return  {
      searchBarPlaceHolder:"ユーザー名、メールアドレスで検索",
      columns: [
        //チェックボックス 日数、ロック時間、ステータスにソート機能をつける
        // {type: "selection",   width: 60,   maxWidth: 50,   align: "center", },
        {key:"userId",slot:'userId', title:'ユーザーID', headSlot:"userIdHead",width: 120, align: 'center'},
        {key:"userName",slot:'userName', title: 'ユーザー名', width: 120,  align: 'center'},
        {key:"role",slot:'role',headSlot:"roleHeader",title: '種別', width: 150,  align: 'center'},
        {key:"email",slot:'email',title:'メールアドレス', width: 220,  align: 'center'},
        {key:"loginId",slot:'loginId', title:'ログインID', width: 150,  align: 'center'},
        {key:"accountCreated",slot:'accountCreated', title:'アカウント作成日時', width: 140,  align: 'center'},
        {key:"lockedTime",slot:'lockedTime',headSlot:"lockedTimeHead", title:'ロック日時', width: 140,  align: 'center'},
        {key:"createdPassedDate",slot:'createdPassedDate', title:'経過日数',headSlot:"createdPassedDateHead", width: 90, align: 'center'},
        {key:"hospitalName",slot:'hospitalName', title:'医療機関名', width: 150,  align: 'center'},
        {key:"agencyName",slot:'agencyName', title:'販売店名', width: 150,  align: 'center'},
        {key:"agencyBranchName",slot:'agencyBranchName', title:'販売店事業所', width: 150,  align: 'center'},
        {key:"accountStatusLabel", title:'ステータス',headSlot:"accountStatusLabelHead", width: 150, align: 'center'},
        {key:"after", title:' ',slot:"after", width: 140, align: 'center'},
        // {key:"accountStatus", title:'ステータス',headSlot:"accountStatusLabelHead", width: 150, align: 'center'},
      ],showMailTemplateFlg:false,
      sendMailRowData: {},
      sendMailType: null,
      mailTemplateTitle:null,
    }
  },
  methods: {
    goodPaging: function (isPageChange) {
      if (!isPageChange) this.pagingParams.currPage = 1;//ページが以外が変わったら最初から検索する
      // this.pagingParams['perPage'] = this.perPage;
      // this.pagingParams['perPage'] = this.pagingParams.perPage;
      this.isLoading = true;
      this.axios.post('/api/user/idManage', this.pagingParams, {headers: {'Content-Type': 'application/json'}}).then(response => {
        this.totalRecords = response.data.result.totalElements;
        this.rows = response.data.result.content;
        //ページ件数
        this.pages = response.data.result.totalPages;
        this.topLeft();
      }).catch(error => console.log(error)
      ).finally(() => {
        this.isLoading = false;
      });
    },
    showMailTemplate(row,index,type){
      console.log(row,index,type);
      // row.originalIndex = index;
      this.showMailTemplateFlg=true;
      this.sendMailRowData = row;
      this.sendMailType = type;
      if(this.sendMailType == 'resend') {
        this.mailTemplateTitle = "ログインメール再送";
      }else if(this.sendMailType == 'extendPeriod'){
        this.mailTemplateTitle = "ログイン期間延長";
      }else if(this.sendMailType == 'releaseLock'){
        this.mailTemplateTitle = "ロック解除";
      }
    },
    callMailMethod(){
      this.showMailTemplateFlg=false;
      if(this.sendMailType == 'extendPeriod' || this.sendMailType == 'resend'){
        this.extendPeriod(this.sendMailRowData);
      }else if(this.sendMailType == 'releaseLock'){
        this.releaseLock(this.sendMailRowData);
      }
    },
    //メール再送期限延長
    extendPeriod(row){

      this.isLoading = true;
      this.axios.post(`/api/user/userLoginExtention/${row.userId}`).then(response => {
        this.rows[row.originalIndex] = Object.assign(this.rows[row.originalIndex],{notLoginAbleFlg:false,createdPassedDate:0});
      }).catch(error => console.log(error)
      ).finally(() => {
        this.isLoading = false;
      });
    },
    //ロック解除
    releaseLock(row){
        this.isLoading = true;
        this.axios.post(`/api/user/userLockRelease/${row.userId}`).then(response => {
          // this.rows[row.originalIndex] = Object.assign(this.rows[row.originalIndex],{lockedTime:null});
          this.goodPaging()
        }).catch(error => console.log(error)
        ).finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {

  },
  computed: {

  }
}

</script>
<style>
/*bee*/
/*.v-table-row > span{*/
/*  font-size: 10px;*/
/*}*/

/*th[class^="bee-table-column-"]{*/
/*  background-color: #0a3e0a;*/
/*  border-top:0;*/
/*  border-bottom:0;*/
/*}*/

/*th > .bee-table-cell{*/
/*  color:lightgray;*/
/*}*/
/*.bee-table-wrapper{*/
/*  border-top:0px;*/
/*}*/
/*.bee-table-cell span{*/
/*  font-weight:bold;*/
/*  font-size: 1rem;*/
/*}*/
/*.bee-table-tip span{*/
/*  color:black;*/
/*}*/

/*li.page-item.disabled{*/
  /*display:none;*/
  /*visibility: hidden;*/
/*}*/
/* bee end */
.bee-table-row-hover{
  cursor: pointer;
}
</style>